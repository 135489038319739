.css-nz481w-MuiInputBase-input-MuiInput-input{
    padding: 0px !important;
}

.css-1710avh-MuiTableRow-root>.MuiTableCell-root .MuiInputBase-input{
    padding: 0px !important;
}

/* In your global CSS file */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}