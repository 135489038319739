@font-face {
    font-family: 'clashGrotesk';
    src: url('/src/fonts/ClashGrotesk-Variable.ttf') format('truetype');
}

@font-face {
    font-family: 'nunito';
    src: url('/src/fonts/nunito.ttf') format('truetype');
}



.pro-sidebar-inner {
    background-color: #272727 !important;
}
.pro-menu-item {
    width: 235px !important;
}
.pro-inner-item .pro-icon-wrapper {
    margin-right: 0px !important;
}



* {
    font-family: 'clashGrotesk' !important;
}





p,.pro-inner-item a,td,input,select,.MuiNativeSelect-select{
    font-family: 'nunito' !important;
}
p,td,input,.MuiNativeSelect-select{
    font-size: 14px !important;
    font-weight: 500 !important;
}


.MuiTableCell-root {
    font-size: 14px;
    font-weight: 500;
 }
 
 .pro-sidebar .pro-menu a,.pro-sidebar .pro-menu p {
     font-size: 14px !important;
 }
 
 .app .css-yqcod .pro-sidebar {
    width: 273px !important;
    min-width: 256px !important;
}
 
 /* input {
     font-size: 14px !important;
 }
 
 .MuiTab-root {
     font-weight: 500 !important;
     font-size: 14px;
 }
 
 span.MuiTypography-root {
     font-size: 18px;
 } */