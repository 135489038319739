/* app.css */

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Roboto", sans-serif;
  overflow: auto;
}

.app {
  display: flex;
  position: fixed;
}

.error {
  font-size: 13px;
  margin-left: 5px;
  color: rgba(240, 9, 9, 0.938);
  font-weight: 500;
}

.sidebarIcons {
  height: 17px;
  width: 17px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
